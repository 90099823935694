/* body{
    mix-blend-mode: screen;

} */

.leftContainer {
    position: fixed;
    top: 2px;
    left: 0px;
    width: 103px;
    height: 100vh;
    background: hsl(236, 30%, 17%);
    border-radius: 4px;
    box-shadow: 0px 0px 1px #171a1f, 0px 0px 2px #171a1f;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-row>span {
    /* flex: 1; */
    justify-content: space-evenly;
}

.black {
    color: black !important;
}


.white {
    color: white !important;

}

.messageme{
    padding: 10px 12px;
    border-radius: 50%;
    background-color: #9277ff;
    position: absolute;
    right: 5%;
    bottom: 5%;
    cursor: pointer;
}

.chat{
    width: 30%;
    background-color: white;
    position: absolute;
    right: 5%;
    bottom: 5%;
    border-radius: 20px;
    border: 1px solid #9277ff;
}

.chathead{
    width:100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #9277ff;
    color: white;
padding:20px 15px 15px 15px;
text-align: center;
}
section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    padding: 50px 0px;

}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 57%;

}



.text {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 34px;
    font-weight: 700;
    line-height: 48px;
    color: #FFFFFFFF;
}

.textinput{
    display:flex;
    width:100%;
padding: 20px 20px 40px 20px;
    flex-direction: column;
}

.close{
    position: absolute;
    top:2%;
    right:4%;
    cursor: pointer;
}

.textinput> button{
    width: 115px;
    text-align: center;
    margin-top: 20px;
}

.new {
    padding-right: 10px;
    width: 180px;
    height: 50px;
    border: none;
    cursor: pointer;
    background: #876FF3FF;
    border-radius: 50px;
    box-shadow: 0px 0px 1px #171a1f, 0px 0px 2px #171a1f;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    margin-right:20px;
}

.new:hover {
    background-color: #9277ff;
}

.new div {
    background-color: white;
    height: 40px;
    width: 40px;
    font-family: 'Times New Roman', Times, serif;
    border-radius: 50%;
    color: #876FF3FF;
    font-size: 35px;

}

.tr {
    width: 100%;
    height: 73px;
    background: hsl(236, 30%, 17%);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid #d1d5db;
}

.tr:hover {
    border: 1px solid #876FF3FF;
}


.light {
    background-color: hsl(0, 0%, 100%) !important;
    color: black !important;
}


.td {
    padding: 10px 20px;
    font-weight: 500;
    /* flex: 1; */
    width: auto;
    flex-basis: 100%;
}
.moon{
    position: absolute;
    bottom: 12%;
    cursor: pointer;
}

.table {
    border-spacing: 0 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.p-paginator {
    background: hsl(236, 30%, 17%);
    position: initial;
    margin-top: 20px;
    margin-bottom: 40px;
}

.white-paginator {
    background: hsl(0, 0%, 100%)
}

.paid {
    color: hsl(169, 37%, 52%);
    background-color: hsl(169, 37%, 52%, 8%);
}

.pending {
    color: hsl(33, 100%, 50%);
    background-color: hsl(33, 100%, 50%, 8%);
}

.paid,
.pending {
    width: auto;
    padding: 10px;
    border-radius: 5px;
}

.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.delete-listitem {
    height: 30px;
    background-color: rgb(199, 90, 67);
    border: none;
    color: white !important;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 15px;
    cursor: pointer;
}

.nodata{
    width: 100%;
    height: 100px;
    display:flex;
    justify-content: center;
    font-family: 'Josefin Sans', sans-serif;

}

.p-dropdown {
    background: transparent;
    border: 1px solid #d1d5db;
}

.p-dropdown-label {
    mix-blend-mode: difference;
    color: white;
}

@media screen and (max-width: 1000px) {

    #home h1{
        width: 90%;
        font-size: 2.2em;
    }

    #home p{
        width: 90%;
        text-align: center;
    }

    #about{
        height: auto;
    }

    #about h2{
font-size: 2.2em !important;
text-align: center;
    }
#how{
    height: auto;
}

.card img{
    width: 200px;
}
    .aboutboxes{
        flex-direction: column;
    }

    .container{
        width: 90%;
        margin-bottom:20px;
    }
    .leftContainer {
        width: 100%;
        height: 90px;
        flex-direction: row-reverse;
        justify-content: center;
        top: 0;
        z-index: 999;
    }

    .header {
        width: 90vw;
        margin-top: 40px;
        font-size: 13px;
        flex-direction: column;
    }

    .table {
        height: auto;
        width: 90vw;
    }


    .tr {
        flex-wrap: wrap;
        height: auto;
    }

    #first {
        width: 70px;
    }


    .delete-listitem {
        display: none;
    }

    .pending,
    .paid {
        width: 120px;
        text-align: center;
    }

    .arrow {
        display: none;
    }

    .p-paginator {
        width: 90vw;
        padding: 8px;
    }

    section{
        height: 100vh;
    }



    .moon {
       bottom:unset;
       right:12%;
    }
}