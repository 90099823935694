body{
  width: 100vw;
  height: 100vh;
  display:flex;
  justify-content: center;
  align-items: center;
}

.signout{
  color: white;
  position: absolute;
  left: 25px;
  bottom: 20px;
}

.login{
  height: 100vh;
  justify-content: center;
}
.signout:hover{
border: 3px solid #876FF3FF;
}


.card{
width: 500px;
border: 1px solid grey;
border-radius: 20px;
padding: 20px;
display: flex;
flex-direction: column;
height:auto;
align-items: center;

}

.card > form{
  width: 100%
}

.email{
  width: 100%;
}

.p-menuitem-text{
  margin-left: 10px;
}

.submit{
  margin-top: 20px;
  width: 100%;
}

.soc{
  background: none;
  color: #6366f1;
  flex-direction: row-reverse;
width: 100%;
}


.line hr {
  border: none;
  border-top: 1px solid #333;
  color: #333;
  overflow: visible;
  text-align: center;
  margin-top:21px;
}

.line hr::after {
 background: #fff;
  content: 'vai';
  padding: 0 4px;
  position: relative;
  top: -10px;
}

@media screen and (max-width: 1000px) {
.card{
  width: 90%;
  margin: auto;
}
}