.create{
    width: 900px;
    background-color: hsl(0, 0%, 100%);
    height: 100vh;
   position: absolute;
    top: 0;
    padding: 20px;
    left: 103px;
overflow-y: scroll;
}

.in{
    animation-name: slidein;
    animation-duration: 0.5s;
    animation-timing-function: linear;
}

.out{

    animation:slideout 0.5s normal backwards ease-in-out;
}

.createHeader{
   display: flex;
    height: 40px;
    flex-direction: row;
   align-items: center;
   cursor: pointer;

}

.flex-row{
    display:flex;
    flex-direction: row;
    width: 850px;
    align-items: center;
    height: 60px;
    justify-content: space-between;
}

.flex-column{
    display: flex;
    flex-direction: column;
}

.flex-start{
    display:flex;
    flex-direction: row;
    width: 250px;
    align-items: center;
    height: 60px;
    justify-content: space-between;

}

.addbutton{
    margin-top: 10px;
    padding: 10px;
color: black;
border-radius: 10px;
}
.invoice{
    padding: 10px;
    background: #876FF3FF;
    color: black;
}

.cancel{
    padding: 10px;
    color: black;

}

h2{
    font-size: 18px !important;
    color: black;
}

.form{
    position: absolute;
    padding-bottom: 80px;
}
.hidden{
    display: none;
}

.p-calendar{
    height: 40px;
}

.transform{
transform: rotate(0.5turn);
}

.delete{
cursor: pointer;
color: hsl(0, 2%, 33%)
}

.delete-tooltip {
    color:red !important;
    font-size: 12px !important;
}

label:not(.nomargin){
    margin-right: 10px;
}

.margin{
    margin-right: 20px;
}

.grid{
    display: grid;
    grid-template-columns: auto auto ;
    padding: 10px;
    grid-auto-flow: row dense;
}

.grid .inrow{
    display: flex;
    padding:10px;
    
}


.inrow label, .p-inputtext-sm{
    flex-basis: 100% !important;
    width: 90%;
}

.flexin{
    display: flex;
    margin-top:20px;
}
input#documentNr{
   margin-right: 20px;
}




@keyframes slidein {
    from {  width: 0px;}
   to{  width: 800px;}
  }

  @keyframes slideout{
  from {  width: 800px;}

    to   {  width: 0px;}  
  }

  @media screen and (max-width: 1000px) {
    .create{
        width: 100vw;
        left: 0;
        top: 12%;
        height: 100vh;
    }
label{
    place-self:unset;
}
    .grid{
        grid-template-columns: auto;
    }

    .grid .inrow{
        padding: 0;
    }

    form{
        width: 100vw;
    }

    #comment{
        width: 90vw !important;
    }
.flex-row{
    flex-direction: column;
    align-items: flex-start;
    width: 90vw;
    height: auto;
}

.inrow{
    display:flex;
    flex-direction: column;
 margin-bottom: 20px;
}

.flex-1, .flex-auto{
    display:flex;
    flex-direction: column;
}


.row2{
    flex-direction: row !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.row2 p{
    width: 90%;
}

.p-inputnumber{
    max-width: 120px;
}

  }